<template>
  <StaticView :heading="$t('pages.faq')">
    <!-- GERMAN -->
    <div
      v-if="$i18n.locale === 'de'"
      class="grid grid-rows-auto leading-8 text-darkerGray text-justify"
    >
      <p class="text-[20px] font-semibold mb-[1rem] uppercase">
        Die wichtigsten Fragen und Antworten rund um das ECORE-Tool
      </p>

      <div class="my-5">
        <p class="faq-header">
          Funktionen/ Rollen der Mitglieder im Online-Tool
        </p>
        <p class="italic">
          Welche Funktionen kann ich als ECORE-Mitglied im Online-Tool nutzen
          und welche Aufgaben erfüllen die unterschiedlichen Funktionen?
        </p>
        <p class="font-medium mt-3">Account Manager</p>
        <p class="italic">Für Portfolio- / Fonds- / Asset Manager</p>
        <ul class="faq-list">
          <li>
            Anlegen/ Editieren von weiteren Nutzern (Mitarbeiter/ externe
            Ansprechpartner)
          </li>
          <li>
            Zuordnung von weiteren Nutzern zu Objektgruppen und Objekten
          </li>
          <li>
            Anlegen/ Editieren / Löschen von Objektgruppen (bspw. Portfolio/
            Fonds)*
          </li>
          <li>
            Anlegen/ Editieren/ Löschen von Assets/ Objekten und deren Zuordnung
            zu den einzelnen Objektgruppen*
          </li>
          <li>Anlegen und Bearbeiten des Cluster I für eine Objektgruppe*</li>
          <li>Fragebogen einsehen/ editieren/ exportieren**</li>
        </ul>
        <p class="italtic">
          *Hierfür muss dem Benutzer ebenfalls die Rolle Objektadministrator zugeteilt werden 
          **Hierfür müssen dem Benutzer ebenfalls die Rollen Objekt Administrator und Objektdatenerfasser zugeteilt werden</p>
        <p class="font-medium mt-3">Objekt Administrator</p>
        <p class="italic">Für Portfolio- / Fonds- / Asset Manager</p>
        <ul class="faq-list">
          <li>
            Anlegen/ Editieren/ Löschen von Objektgruppen (bspw. Portfolio/ Fonds)
          </li>
          <li>
            Anlegen/ Editieren/ Löschen von Assets/ Objekten und deren Zuordnung zu den einzelnen Objektgruppen
          </li>
          <li>
            Anlegen und Bearbeiten des Cluster I für eine Objektgruppe*
          </li>
          <li>
            Fragebogen einsehen/ editieren/ exportieren/ aktualisieren*
          </li>
        </ul>
        <p class="italtic">
          *Nur für Objektgruppen/ Objekte, für die der Benutzer freigeschalten wurde. Hierfür muss dem Benutzer ebenfalls die Rolle Objektdatenerfasser zugeteilt werden</p>
        <p class="font-medium mt-3">Objektdatenerfasser</p>
        <p class="italic">Für Asset-/ Property-/ Facility Manager</p>
        <ul class="faq-list">
          <li>
            Unterstützung/ Vervollständigung der Gebäudeeckdaten von
            zugeordneten Assets/ Objekten
          </li>
          <li>
            Beantworten der Fragebögen für die zugeordnete Assets/ Objekte
          </li>
          <li>
            Fragebogen einsehen/ editieren/ exportieren für zugeordnete Assets/ Objekte
            <ul class="faq-list ml-5">
              <li>Inkl. Kopierfunktion für Cluster I</li>
              <li>
                Cluster I ist für externe Objektdatenerfasser nicht einsehbar
              </li>
            </ul>
          </li>
        </ul>
        <p>
          Ein Benutzer kann zeitgleich mehrere Rollen zugewiesen bekommen! 
          Die Rolle des Account Managers kann nur durch ECORE vergeben werden. 
          Sollten Sie weitere Account Manager für Ihre Organisation benötigen, wenden Sie sich bitte an support@ecore-rating.com
        </p>
      </div>

      <div class="my-5">
        <p class="faq-header">Updates zum Tool / Livebetrieb</p>
        <p class="italic">
          Hinweise zum Update 13.06.2022
        </p>
        <ul class="faq-list">
          <li>
            Neben den neuen Fragenkatalogen (Wohnen, Nahversorger, Fachmarktzentren, Pflege- und
            Sozialimmobilien) gibt es auch für die aktuellen Kataloge (Büro, Hotel, Logistik, Handel (high
            street)) Neuerungen.
            Insbesondere bei den Multiple Choice Fragen haben wir das Tool noch einmal optimiert,
            sodass nun exklusive Antworten in den Fragen enthalten sind (Sie können nicht zeitglich
            „nein“ und „ja“ antworten).
            Dies führt bei einem Update dazu, dass Sie die betreffenden Multiple Choice-Fragen
            nochmals beantworten müssen.
            Wir empfehlen Ihnen einen Export Ihres Fragebogens zu ziehen, bevor Ihr
            Objektadministrator das Update für einen Fragebogen ausführt. Um Ihnen die Arbeit zu
            erleichtern, anbei eine Auflistung der Fragen, die Sie nach dem Update neu beantworten
            müssten:
            <ul>
              <li>CLU III 1.1 - Antwortoptionen sind nun exklusiv</li>
              <li>CLU III 1.2 - Antwortoptionen sind nun exklusiv</li>
              <li>CLU III 1.3 - Antwortoptionen sind nun exklusiv</li>
              <li>CLU III 2.13 - Antwortoptionen sind nun exklusiv</li>
              <li>CLU III 3.3 - Antwortoptionen sind nun exklusiv</li>
              <li>CLU III 3.4 - Antwortoptionen sind nun exklusiv</li>
              <li>CLU III 3.6 - Antwortoptionen sind nun exklusiv</li>
              <li>CLU III 4.3b - Antwortoptionen sind nun exklusiv</li>
              <li>CLU III 4.4 - Antwortoptionen sind nun exklusiv</li>
              <li>CLU III 4.8 - Antwortoptionen sind nun exklusiv</li>  
              <li>CLU III 4.9 - Antwortoptionen sind nun exklusiv</li> 
              <li>CLU III 4.10 - Antwortoptionen sind nun exklusiv</li>
              <li>CLU III 4.12 - Antwortoptionen sind nun exklusiv</li>
              <li>CLU III 4.13 - Antwortoptionen sind nun exklusiv</li> 
              <li>CLU III 5.4 - Antwortoptionen sind nun exklusiv</li>
              <li>CLU III 5.5 - Antwortoptionen sind nun exklusiv</li> 
              <li>CLU III 6.4 - Antwortoptionen sind nun exklusiv</li>
              <li>CLU III 7.8 - Antwortoptionen sind nun exklusiv</li>
              <li>CLU III 7.16 - Antwortoptionen sind nun exklusiv</li>                         
            </ul>
            Hinweis: das Update kann nur von dem für die Objekte zuständigen Objektadministrator
            ausgeführt werden und wird nicht automatisch über das Tool ausgerollt. Solange Ihr
            Objektadministrator das Update nicht freigibt, können Sie weiter auf der aktuellen Version
            Ihres Fragebogens arbeiten.
          </li>
        </ul>
        <p class="italic">
          Wird es weiterhin Updates zum Tool geben? Werden veränderte
          Anforderungen auch in bestehenden Fragenkatalogen abgebildet werden?
        </p>
        <ul class="faq-list">
          <li>
            Auch in der Nutzungsphase werden wir ECORE mit seinen diversen
            Fachausschüssen und sich ändernden Anforderungen (z.B. die
            anstehende S- und G-Taxonomie) weiterhin wie gewohnt steuern und
            vorantreiben. Über Neuerungen und Aktualisierungen werden Sie auf der
            Startseite informiert werden.
          </li>
        </ul>
      </div>

      <div class="my-5">
        <p class="faq-header">Wesentlichkeitsgrenze für Assetklassen</p>
        <p class="italic">
          Wie gehe ich mit Mixed-Use-Objekten um und welcher Assetklasse kann
          ich die Nutzung zuordnen?
        </p>
        <ul class="faq-list">
          <li>
            Sollte eine Assetklasse über 85% der Fläche des Gebäudes ausmachen
            sind die anderen Flächen zu vernachlässigen und nur die Hauptnutzung
            zu erfassen. Ansonsten muss je Assetklasse ein separater Bogen
            ausgefüllt werden bzw. das Objekt mit den jeweiligen
            Hauptassetklassen angelegt werden.
          </li>
        </ul>
        <p class="italic mt-3">
          Ein Gebäude besteht aus zwei Bauteilen (ggf. mit unterschiedlichen
          Adressen). Muss hierfür ein Bogen ausgefüllt werden, oder zwei Bögen?
        </p>
        <ul class="faq-list">
          <li>
            Unter der Voraussetzung, dass die Gebäudeteile der gleichen
            Assetklasse angehören und sie auch technisch über eine Anlage
            versorgt (Technik, Heizungsanlage/ eine EnEV) und abgerechnet werden
            (Verbrauchsdaten) reicht es, beide Gebäudeteile im selben Bogen zu
            erfassen.
          </li>
        </ul>
        <p class="italic mt-3">
          Ein Projekt besteht aus mehreren Stand-Alone Objekten, die sich
          dieselbe Adresse teilen. Ausstattung / Bauart ist bei allen Objekten
          identisch, lediglich der Energieverbrauch unterscheidet sich. Kann
          hier ein Bogen mit dem Durchschnittsverbrauch über die Objekte befüllt
          werden oder muss pro Objekt ein eigener Bogen befüllt werden?
        </p>
        <ul class="faq-list">
          <li>Für jedes Objekt ist ein eigener Bogen zu befüllen.</li>
        </ul>
      </div>

      <div class="my-5">
        <p class="faq-header">Abgrenzung von Handelsimmobilienformaten (ohne Innenstadt- und Hybridformate)</p>
        <table cellspacing=12>
          <tr>
            <th></th>
            <th>Shoppingcenter</th>
            <th>Fachmarktzentrum</th>
            <th>Nahversorger</th>
          </tr>
          <tr>
            <th>Ladenmietfläche</th>
            <td>ab 10.000 m²</td>
            <td>ab 5.000 m²</td>
            <td>bis 5.000 m²</td>
          </tr> 
          <tr>
            <th>Anzahl Läden</th>
            <td>ab 30</td>
            <td>ab 5</td>
            <td>bis 15</td>
          </tr> 
          <tr>
            <th>Einzugsgebiet Einwohner</th>
            <td>ab 50.000 EW</td>
            <td>ab 20.000 EW</td>
            <td>ab 3.000 EW</td>
          </tr> 
          <tr>
            <th>Einzugsgebiet Fahrzeit</th>
            <td>bis 60 Min</td>
            <td>bis 30 Min</td>
            <td>bis 15 min</td>
          </tr> 
          <tr>
            <th>Verweildauer</th>
            <td>ab 45 Min</td>
            <td>ab 30 Min</td>
            <td>bis 45 Min</td>
          </tr> 
          <tr>
            <th>Besuchshäufigkeit</th>
            <td>wöchentlich bis jährlich</td>
            <td>wöchentlich bis jährlich</td>
            <td>täglich bis wöchentlich</td>
          </tr>
          <tr>
            <th>Textilquote in % der Ladenfläche</th>
            <td>ab 40%</td>
            <td>bis 50%</td>
            <td>bis 15%</td>
          </tr>
        </table>
      </div>

      <div class="my-5">
        <p class="faq-header">Europäischer Ansatz</p>
        <p class="italic">
          Welche Länder werden im dem Fragenkatalog berücksichtigt?
        </p>
        <ul class="faq-list">
          <li>
            Für ECORE gilt seit der ersten Stunde: ein europäischer und
            weiterführend internationaler Ansatz. Sowohl in der Nutzung des
            CRREM-Tools als auch im späteren Benchmark. In Kürze wird das Tool
            neben der deutschen und englischen Sprachversion auch auf
            Französisch, Italienisch und Schwedisch zur Verfügung stehen.
          </li>
        </ul>
      </div>

      <div class="my-5">
        <p class="faq-header">Integration des CRREM-Tools</p>
        <p class="italic">
          Wird auch der Dekarbonisierungspfad über CRREM mit abgebildet?
        </p>
        <ul class="faq-list">
          <li>
            Das CRREM Tool ist im Cluster II integriert. Mit der Eingabe Ihrer
            Verbrauchsdaten (Endenergieverbrauch &amp; CO2-Emissionen) werden
            Ihnen die aktuellen Abweichungen vom Dekarbonisierungspfad in
            Prozent sowie der Stranding Point angezeigt.
          </li>
        </ul>
      </div>

      <div class="my-5">
        <p class="faq-header">Schnittstellen &amp; externe Dienstleister</p>

         <p class="italic mt-3">
          Kann mein externer Dienstleister den Fragebogen für mich befüllen?
        </p>
        <ul class="faq-list">
          <li>
            Mit der Funktion des Objektdatenerfassers können Sie Ihre externen
            Dienstleister für die Befüllung einzelner Fragebögen freischalten
            lassen.
          </li>
          <li>
              Eine Anbindung über eine Schnittstelle ist ebenfalls möglich
          </li>
        </ul>
        <p class="italic">
          Welche Schnittstellen gibt es? In welcher Form werden die Daten übermittelt?
        </p>
        <ul class="faq-list">
          <li>
            Um den Erhebungsaufwand mit ECORE möglichst gering zu halten, ist ein Anbindung von externen Dienstleistern/ Datenmanagementsystemen zur Datenübermittlung an ECORE obligatorisch. 
          </li>
          <li>
            ECORE bietet hierfür eine API-Schnittstelle an. Nach vertraglicher Absprache mit ECORE (nutzen Sie hierfür bitte den Kontakt support@ecore-rating.com ) erhalten Schnittstellenpartner einen Zugangscode. Der Zugangscode erlaubt eine Authentifizierung gegenüber dem System.
          </li>
        </ul>
        <p class="italic mt-3">
          Wie ist die Schnittstelle zum Tool gesichert?
        </p>
        <ul class="faq-list">
          <li>
           Die Schnittstelle ist gesichert, sodass nur authentifizierte NutzerInnen Daten sehen können. Diese authentifizierten NutzerInnen können nur die für sie zugänglichen Daten einsehen.
          </li>
          <li>
            Zugriff auf die Schnittstelle haben lediglich die oben beschriebenen Schnittstellenpartner mit ihrem Zugangscode.
          </li>
        </ul>
      </div>

      <div class="my-5">
        <p class="faq-header">Datensicherheit/ -speicherung</p>
        <p class="italic">Wie wird die Sicherheit meiner Daten gewährleistet?</p>
        <p> Ihre Daten sind auf verschiedenen Ebenen geschützt:</p>
        <ul class="faq-list">
          <li>Rechenzentrum</li>
           <ul class="faq-list ml-5">
            <li>Die Daten werden ausschließlich auf deutschen, zertifizierten Servern gespeichert und verarbeitet.</li>
            <li>Das Rechenzentrum liegt in Frankfurt am Main und wird durch Microsoft betrieben. Alle Prüfberichte
                und Zertifikate können bei Microsoft eingesehen werden. Links: <a href="https://servicetrust.microsoft.com/">Microsoft Prüfberichte</a>
                , <a href = "https://www.microsoft.com/licensing/docs/view/Microsoft-Products-and-Services-Data-Protection-Addendum-DPA">
                Microsoft PDA (Products and Services Data Protection Addendum)</a></li>
            </ul>
          <li> Anwendungsebene</li>
            <ul class="faq-list ml-5">
              <li>Die Daten sind nur pro Mandant zugänglich. Mit Ausnahme von ECORE und Appsfactory kann niemand
               die Daten von anderen Organisationen sehen. Die Abgrenzung erfolgt auf Anwendungslogik.</li>
              <li>Die Schnittstellen sind gesichert, sodass nur authentifizierte NutzerInnen die jeweiligen Daten sehen können. 
              Die authentifizierten NutzerInnen können nur die für sie zugänglichen Daten einsehen.</li>
            </ul>
          <li> Übertragungsebene </li>
          <ul class="faq-list ml-5">
              <li>Die Daten werden mittels einer verschlüsselten Verbindung übertragen. Daher ist das System vor Man-in-the-Middle Angriffe abgesichert.</li>
              <li>Weitere Schutzmechanismen sind in der Anwendung integriert (Bsp.: Schutz for SQL-Injection)</li>
            </ul>
        </ul>
        <p class="italic mt-3">
            Wie ist mein Benutzeraccount gesichert?
        </p>
        <ul class="faq-list">
          <li>
            Die Passwörter werden ausschließlich verschlüsselt abgelegt, sodass selbst im unwahrscheinlichen Fall eines Datenleaks kein Zugriff auf die Daten möglich ist.
          </li>
        </ul>
         <p class="italic mt-3">
            Gibt es ein Backup der Daten und wenn ja, wo liegen die Daten?
        </p>
        <ul class="faq-list">
          <li>
            Es gibt ein Backup, welches die Daten regelmäßig sichert. Die Daten werden ebenfalls auf deutschen Servern, jedoch auf physisch getrennten Einheiten/ Servern gespeichert.
          </li>
        </ul>
         <p class="italic mt-3">
            Können/ sollen auch Dokumente hochgeladen werden oder handelt es sich nur um Daten?
        </p>
        <ul class="faq-list">
          <li>
            Zum jetzigen Stand ist ein reiner Upload von Daten geplant – nicht von Dokumenten.
          </li>
        </ul>
      </div>

      <div class="my-5">
        <p class="faq-header">Echtzeitscore &amp; Auswertung</p>
        <p class="italic">
          Wann kann ich den erreichten ECORE-Score für ein Objekt einsehen?
        </p>
        <ul class="faq-list">
          <li>
            Im Online-Tool können Sie zu jedem Zeitpunkt Ihren ungeprüften
            Echtzeitscore sehen. Dieser Score ist zur alleinigen internen
            Kommunikation gedacht und darf nicht in der externen Kommunikation
            genutzt werden. Dies ist nur für den geprüften ECORE-Score möglich.
          </li>
        </ul>
        <p class="italic mt-3">
          Erfolgt die spätere Auswertung/ Benchmark nur auf Asset-Ebene oder
          können diese auch auf Portfolien (unterschiedliche Clusterung)
          angewandt werden?
        </p>
        <ul class="faq-list">
          <li>
            Das Tool ist in seiner Grundkonzeption auf Portfolien ausgerichtet,
            die sich über die Asset-Ebene aggregieren. Da es sich beim
            ECORE-Scoring um ein Steuerungs-, Kommunikations- und
            Benchmarking-Tool handelt, wird sowohl auf Portfolioebene als auch
            auf Asset-Ebene ein Benchmarking erstellt.
          </li>
        </ul>
      </div>
    </div>

    <!-- ENGLISH -->
    <div
      v-else-if="$i18n.locale === 'en'"
      class="grid grid-rows-auto leading-8 text-darkerGray text-justify"
    >
      <p class="text-[20px] font-semibold mb-[1rem] uppercase">
        The most important questions and answers about the ecore-tool
      </p>

      <div class="my-5">
        <p class="faq-header">Functions/ roles of members in the online tool</p>
        <p class="italic">
          Which functions can I use in the online tool as an ECORE member and
          which tasks do the different functions fulfill?
        </p>
        <p class="font-medium mt-3">Account Manager</p>
        <p class="italic">For portfolio- / fund- / asset managers</p>
        <ul class="faq-list">
          <li>Create/ edit additional users (employees/ external contacts)</li>
          <li>Assignment of additional users to object groups and objects</li>
          <li>Create/ edit/ delete object groups (e.g. portfolio/ fund)*</li>
          <li>
            Create/ edit/ delete assets/ objects and their allocation to the
            individual object groups*
          </li>
          <li>Create and edit cluster I for an object group*</li>
          <li>View/ edit/ export questionnaires**</li>
        </ul>
        <p class="italic">
          *For this purpose, the user must also be assigned to the role object administrator
          **For this purpose, the user must also be assigned the roles account manager and object data collector</p>
        <p class="font-medium mt-3">Object administrator</p>
        <p class="italic">For portfolio- / fund- / asset managers</p>
        <ul class="faq-list">
          <li>Create/ edit/ delete objectgroups (e.g. portfolio/ fund)</li>
          <li>Create/ edit/ delete assets/ objects and their allocation to the individual object groups</li>
          <li>Create and edit cluster I for an object group*</li>
          <li>View/ edit/ export questionnaires*</li>
        </ul>
        <p class="italic">
          *Only for object groups/ objects for which the user has been activated. 
          For this purpose, the user must also be assigned the role object data collector</p>
        <p class="font-medium mt-3">Object data collector</p>
        <p class="italic">For asset-/ property-/ facility managers</p>
        <ul class="faq-list">
          <li>
            Support/ complete building corner data of assigned assets/ objects
          </li>
          <li>Answer questionnaires for assigned assets/objects</li>
          <li>
            View/ edit/ export questionnaires of allocated assets/ objects
            <ul class="faq-list ml-5">
              <li>Incl. copy function for cluster I</li>
              <li>
                Cluster I is not visible for external object data collector
              </li>
            </ul>
          </li>
        </ul>
        <p> 
          A user can be assigned several roles at the same time!
          The account manager role can only be assigned by ECORE. 
          If you need additional account managers for your organization, please contact support@ecore-rating.com
        </p>
      </div>

      <div class="my-5">
        <p class="faq-header">Updates to the tool / live operation</p>
        <p class="italic">
          Notes on the update 13.06.2022
        </p>
        <ul class="faq-list">
          <li>
            In addition to the new questionnaires (residential, local suppliers, retail parks, healthcare
            properties), there are also new features for the current catalogues (office, hotel, logistics,
            retail (high street)).
            Especially for the multiple choice questions, we have optimised the tool once again so that
            exclusive answers are now included in the questions (you cannot answer &quot;no&quot; and &quot;yes&quot; at
            the same time). This means that you will have to answer the relevant multiple choice
            questions again, once you update the tool.
            We recommend to get an export the questionnaire for a specific asset before your object
            administrator performs the update for that questionnaire/ asset. To make your work easier,
            here is a list of the questions you would have to answer again after the update:
            <ul>
              <li>CLU III 1.1 - Answer options are exclusive now</li>
              <li>CLU III 1.2 - Answer options are exclusive now</li>
              <li>CLU III 1.3 - Answer options are exclusive now</li>
              <li>CLU III 2.13 - Answer options are exclusive now</li>
              <li>CLU III 3.3 - Answer options are exclusive now</li>
              <li>CLU III 3.4 - Answer options are exclusive now</li>
              <li>CLU III 3.6 - Answer options are exclusive now</li>
              <li>CLU III 4.3b - Answer options are exclusive now</li>
              <li>CLU III 4.4 - Answer options are exclusive now</li>
              <li>CLU III 4.8 - Answer options are exclusive now</li>  
              <li>CLU III 4.9 - Answer options are exclusive now</li> 
              <li>CLU III 4.10 - Answer options are exclusive now</li>
              <li>CLU III 4.12 - Answer options are exclusive now</li>
              <li>CLU III 4.13 - Answer options are exclusive now</li> 
              <li>CLU III 5.4 - Answer options are exclusive now</li>
              <li>CLU III 5.5 - Answer options are exclusive now</li> 
              <li>CLU III 6.4 - Answer options are exclusive now</li>
              <li>CLU III 7.8 - Answer options are exclusive now</li>
              <li>CLU III 7.16 - Answer options are exclusive now</li>                         
            </ul>
            Please note: the update can only be carried out by the object administrator responsible for
            the objects and will not be rolled out automatically via the tool. As long as your object
            administrator does not release the update, you can continue to work on the current version
            of your questionnaire.
          </li>
        </ul>
        <p class="italic">
          Will there be further updates to the tool? Will changed requirements
          also be considered in the questionnaires?
        </p>
        <ul class="faq-list">
          <li>
            During the usage phase, we will continue to develop ECORE with its
            various technical committees and changing requirements (e.g. the
            upcoming S and G taxonomy) as usual. You will be informed about
            innovations and updates on the homepage.
          </li>
        </ul>
      </div>

      <div class="my-5">
        <p class="faq-header">Materiality threshold for asset classes</p>
        <p class="italic">
          How do I deal with mixed-use objects and to which asset class can I
          assign the use?
        </p>
        <ul class="faq-list">
          <li>
            If an asset class accounts for more than 85% of the building, the
            other asset classes are to be disregarded and only the main use is
            to be recorded. Otherwise, a separate questionnaire must be filled
            out for each asset class.
          </li>
        </ul>
        <p class="italic mt-3">
          A building consists of two components (possibly with different
          addresses). Do I have to fill out one questionnaire or two?
        </p>
        <ul class="faq-list">
          <li>
            Provided that the building parts belong to the same asset class and
            they are also technically supplied (technology, heating system/one
            EnEV) and billed (consumption data) via one system, it is sufficient
            to enter both building parts in the same questionnaire.
          </li>
        </ul>
        <p class="italic mt-3">
          A project consists of several stand-alone objects that share the same
          address. The equipment/construction type is identical for all
          properties, only the energy consumption differs. Can one questionnaire
          be filled with the average consumption across the objects or must a
          separate questionnaire be filled for each object?
        </p>
        <ul class="faq-list">
          <li>A separate questionnaire must be filled in for each property.</li>
        </ul>
      </div>

      <div class="my-5">
        <p class="faq-header">Delimitation of retail property formats (excluding city centre and hybrid formats)</p>
        <table cellspacing=12>
          <tr>
            <th></th>
            <th>Shopping centre</th>
            <th>Retail park</th>
            <th>Local suppliers</th>
          </tr>
          <tr>
            <th>rental area</th>
            <td>from 10.000 m²</td>
            <td>from 5.000 m²</td>
            <td>up to 5.000 m²</td>
          </tr> 
          <tr>
            <th>number of shops</th>
            <td>from 30</td>
            <td>from 5</td>
            <td>up to 15</td>
          </tr> 
          <tr>
            <th>catchment area population</th>
            <td>from 50.000 residents</td>
            <td>from 20.000 residents</td>
            <td>from 3.000 residents</td>
          </tr> 
          <tr>
            <th>catchment area travel time</th>
            <td>up to 60 Min</td>
            <td>up to 30 Min</td>
            <td>up to 15 min</td>
          </tr> 
          <tr>
            <th>duration of stay</th>
            <td>from 45 Min</td>
            <td>from 30 Min</td>
            <td>up to 45 Min</td>
          </tr> 
          <tr>
            <th>frequency of visit</th>
            <td>weekly to yearly</td>
            <td>weekly to yearly</td>
            <td>daily to weekly</td>
          </tr>
          <tr>
            <th>textile share in % of shop space</th>
            <td>from 40%</td>
            <td>up to 50%</td>
            <td>up to 15%</td>
          </tr>
        </table>
      </div>

      <div class="my-5">
        <p class="faq-header">European approach</p>
        <p class="italic">Which countries are included in the questionnaire?</p>
        <ul class="faq-list">
          <li>
            Since the very beginning, ECORE has followed a European and
            international approach. Both in the use of the CRREM tool and in the
            subsequent benchmark. In the near future, the tool will be available
            in French, Italian and Swedish in addition to the German and English
            language versions.
          </li>
        </ul>
      </div>

      <div class="my-5">
        <p class="faq-header">Integration of the CRREM tool</p>
        <p class="italic">
          Is the decarbonisation pathway also mapped via CRREM?
        </p>
        <ul class="faq-list">
          <li>
            The CRREM tool is integrated in Cluster II. When you enter your
            consumption data (final energy consumption &amp; CO2 emissions), the
            current deviations from the decarbonisation pathway are displayed in
            percent as well as the stranding point.
          </li>
        </ul>
      </div>

      <div class="my-5">
        <p class="faq-header">Interfaces &amp; external service providers</p>

         <p class="italic mt-3">
            Can my external service provider fill in the questionnaire for me?       
         </p>
        <ul class="faq-list">
          <li>
           With the object data collector function, you can have your external service providers enabled to fill in individual questionnaires.
          </li>
          <li>
            A connection via an interface is also possible.          </li>
        </ul>
        <p class="italic">
            Which interfaces are available? In what form is the data transmitted?        </p>
        <ul class="faq-list">
          <li>
            In order to keep the survey effort with ECORE as low as possible, a connection of external service providers/data management systems for data transmission to ECORE is obligatory.           </li>
          <li>
            ECORE offers an API interface for this purpose. After contractual agreement with ECORE (please use the contact support@ecore-rating.com ), interface partners receive an access code. The access code allows authentication towards the system.          </li>
        </ul>
        <p class="italic mt-3">
          How is the interface to the tool secured?        </p>
        <ul class="faq-list">
          <li>
            The interface is secured so that only authenticated users can see the data. These authenticated users can only see the data that is accessible to them.          </li>
          <li>
            Only the interface partners described above have access to the interface with their access code.          </li>
        </ul>
      </div>

     <div class="my-5">
        <p class="faq-header">Data security/storage</p>
        <p class="italic">How is the security of my data guaranteed?</p>
        <p> Your data is protected at various levels:</p>
        <ul class="faq-list">
          <li>Rechenzentrum</li>
           <ul class="faq-list ml-5">
            <li>The data is stored and processed exclusively on German, certified servers.</li>
            <li>The data centre is located in Frankfurt am Main and is operated by Microsoft. All test reports and certificates can be viewed at Microsoft. Links: <a href="https://servicetrust.microsoft.com/">Microsoft Prüfberichte</a>
                , <a href = "https://www.microsoft.com/licensing/docs/view/Microsoft-Products-and-Services-Data-Protection-Addendum-DPA">
                Microsoft PDA (Products and Services Data Protection Addendum)</a></li>
            </ul>
          <li>Application level</li>
            <ul class="faq-list ml-5">
              <li>The data is only accessible per client. With the exception of ECORE and Appsfactory, no one can see the data from other organisations. The demarcation is done on application logic.</li>
              <li>The interfaces are secured so that only authenticated users can see the respective data. The authenticated users can only see the data that is accessible to them.</li>
            </ul>
          <li>Transmission level </li>
          <ul class="faq-list ml-5">
              <li>The data is transmitted via an encrypted connection. Therefore, the system is secured against man-in-the-middle attacks.</li>
              <li>Further protection mechanisms are integrated in the application (e.g.: protection for SQL injection).</li>
            </ul>
        </ul>
        <p class="italic mt-3">
            How is my user account secured?
        </p>
        <ul class="faq-list">
          <li>
            The passwords are stored exclusively in encrypted form, so that even in the unlikely event of a data leak no access to the data is possible.
          </li>
        </ul>
         <p class="italic mt-3">
            Is there a backup of the data and if so, where is that data stored?
        </p>
        <ul class="faq-list">
          <li>
           There is a regular backup for the data. That backup is also stored on German servers, but on physically separate units/ servers.
          </li>
        </ul>
         <p class="italic mt-3">
            Can/ should documents also be uploaded or is it only data?   
         </p>     
        <ul class="faq-list">
          <li>
            At the current stage, it is planned to upload data only - not documents.
          </li>
        </ul>
      </div>

      <div class="my-5">
        <p class="faq-header">Real-time score &amp; evaluation</p>
        <p class="italic">
          When can I see the ECORE score achieved for an object?
        </p>
        <ul class="faq-list">
          <li>
            In the online tool you can see your unchecked real-time score at any
            time. This score is intended for internal communication only and must
            not be used in external communication; this is only possible for the
            certified ECORE score.
          </li>
        </ul>
        <p class="italic mt-3">
          Is the subsequent evaluation/ benchmark only at asset level or can
          these also be applied to portfolios (different clustering)?
        </p>
        <ul class="faq-list">
          <li>
            The basic concept of the tool is geared towards portfolios that
            aggregate via the asset level. Since ECORE scoring is a controlling,
            communication and benchmarking tool, benchmarking is carried out
            both at portfolio level and at asset level.
          </li>
        </ul>
      </div>
    </div>
  </StaticView>
</template>

<script>
import StaticView from '@/components/layout/StaticView'
export default {
  components: {
    StaticView,
  },
}
</script>

<style scoped>
.faq-list {
  @apply list-disc list-outside ml-7;
}
.faq-header {
  @apply font-semibold text-[18px];
}
</style>
